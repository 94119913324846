exports.components = {
  "component---src-layout-template-legal-js": () => import("./../../../src/layout/templateLegal.js" /* webpackChunkName: "component---src-layout-template-legal-js" */),
  "component---src-layout-template-page-js": () => import("./../../../src/layout/templatePage.js" /* webpackChunkName: "component---src-layout-template-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-contacter-nous-js": () => import("./../../../src/pages/contact/contacter-nous.js" /* webpackChunkName: "component---src-pages-contact-contacter-nous-js" */),
  "component---src-pages-contact-devis-js": () => import("./../../../src/pages/contact/devis.js" /* webpackChunkName: "component---src-pages-contact-devis-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nous-contacter-merci-js": () => import("./../../../src/pages/nous-contacter-merci.js" /* webpackChunkName: "component---src-pages-nous-contacter-merci-js" */)
}

